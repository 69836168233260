@font-face {
  font-family: "LemonTuesday";
  src: url("./fonts/LemonTuesday.otf") format("opentype");
}

:root {
  --sonjacodes-brand-color: #F53B9B;
  --sonjacodes-brand-color-secondary: #D9D9D9;
  --sonjacodes-brand-font: "LemonTuesday", cursive;
}

body {
  background: black;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.App {
  background: white;
  border-radius: 4px;
  margin: 0 auto;
  min-width: 370px;
  max-width: 960px;
  margin-top: 88px;
  padding: 10px;
  text-align: center;
}

h1 {
  color: var(--sonjacodes-brand-color);
  font-family: "Playfair Display", serif;
}

.btn-primary {
  background-color: black;
  border: black;
  color: var(--sonjacodes-brand-color);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
  transition-duration: 0.1s;
}

.btn-primary:hover {
  border: none;
  color: white;
  font-weight: bold;
  background-image: linear-gradient(to right, fuchsia, orange, yellow, green, blue, indigo, violet, fuchsia);
  animation: slidebg 4s linear infinite;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}