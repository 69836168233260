.contact-options {
  position: absolute;
  top: 100%;
  right: 0;
  background: #d9d9d9;
  padding: 0.5rem;
  border: none;
  margin: 0;
}

.contact-options a {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

@media (max-width: 768px) {
  .contact-options {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}