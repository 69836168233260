.Footer {
  color: var(--sonjacodes-brand-color-secondary);
  font-size: 12px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: center;
}

.Footer .Links {
  margin: 20px 0;
}

.Footer .Links a {
  padding: 0 30px;
}

.Footer a {
  color: var(--sonjacodes-brand-color);
  font-weight: 500;
  text-decoration: none;
}

.Footer a:hover {
  background-color: var(--sonjacodes-brand-color-secondary);
  border-radius: 2px;
  font-weight: 600;
  opacity: 1;
  text-decoration: none;
}