.SonjaCodes {
  color: var(--sonjacodes-brand-color);
  font-family: var(--sonjacodes-brand-font);
  font-size: 28px;
  font-weight: 600;
  text-decoration: none;
}

.Home h2,
.About h2,
.MyResume h2,
.WorkEnquiry h2,
.ContactDetails h2 {
  font-family: "Playfair Display",
    serif;
  font-size: 36px;
  padding: 40px 0;
}

.Home h3,
.MyResume h3 {
  font-family: "Playfair Display",
    serif;
  font-size: 24px;
  padding: 40px 0;
}

.Home p {
  border: 8px var(--sonjacodes-brand-color-secondary);
  border-style: dashed solid;
  border-radius: 4px;
  padding: 30px;
  margin: 20px 0;
  line-height: 28px;
}

.Home img {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Home .btn {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 30px 0;
}

.About .img {
  border: 5px solid var(--sonjacodes-brand-color-secondary);
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 20px 0;
  padding: 3px;
  width: 400px;
}

.About p {
  line-height: 24px;
  padding: 30px;
  margin: 20px 0;
}

.About .skillIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.About .skillIcon {
  padding: 20px;
  width: 55px;
}

.About .skillIcon:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 1;
}

.About .attribution {
  color: var(--sonjacodes-brand-color-secondary);
  font-size: 12px;
  padding: 10px 0;
}

.About a {
  color: var(--sonjacodes-brand-color-secondary);
  text-decoration: none;
}

.About a:hover {
  text-decoration: underline;
}

.MyProjects .card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.MyProjects .card {
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  height: 300px;
  width: 300px;
  margin: 10px auto;
  overflow: visible;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  cursor: help;
}

.MyProjects .card img {
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: border-box;
  object-position: center;
}

.MyProjects .githubLink {
  color: var(--sonjacodes-brand-color);
  font-weight: 500;
  text-decoration: none;
}

.MyProjects .githubLink:hover {
  font-weight: 600;
  text-decoration: underline;
}

.MyProjects .card:hover {
  --translate-y: -5px;
}

.MyProjects .card.flip {
  --rotate-y: 180deg;
}

.MyProjects .card .front,
.MyProjects .card .back {
  position: absolute;
  padding: 20px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.MyProjects .card .back {
  transform: rotateY(180deg);
}

.MyResume p {
  padding: 20px 0;
}

.MyResume form {
  display: block;
  flex-direction: column;
  margin: 0 auto;
}

.MyResume input {
  border: 2px solid var(--sonjacodes-brand-color);
  border-radius: 4px;
  font-size: 18px;
  padding: 5px;
  margin: 0 5px;
  width: 120px;
}

.MyResume .no-password {
  font-size: 12px;
  margin-top: 188px;
}

.MyResume .submit-btn {
  background-color: var(--sonjacodes-brand-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-family: var(--sonjacodes-brand-font);
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
}

.MyResume button {
  all: unset;
  color: var(--sonjacodes-brand-color);
  cursor: pointer;
}

.MyResume button:hover {
  text-decoration: underline;
}

.MyResume .download-btn {
  margin-bottom: 30px;
}

.ContactDetails .icon-text-columns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContactDetails .icon-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.ContactDetails .text-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactDetails svg,
.ContactDetails span {
  margin-bottom: 20px;
}

.ContactDetails .btn {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 40px 0;
}

.WorkEnquiry .form-container {
  padding: 10px;
  border: 8px var(--sonjacodes-brand-color-secondary);
  border-style: dashed solid;
  border-radius: 4px;
  width: 300px;
  margin: 30px auto;
}

.WorkEnquiry textarea,
.WorkEnquiry select,
.WorkEnquiry input {
  background-color: var(--sonjacodes-brand-color-secondary);
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 3px solid var(--sonjacodes-brand-color);
  padding: 10px 15px;
  margin-bottom: 5px;
  font-size: 14px;
}

.MyResume label,
.WorkEnquiry label {
  font-family: var(--sonjacodes-brand-font);
  font-size: 18px;
  margin-bottom: 5px;
}

.WorkEnquiry input[type="submit"] {
  background: var(--sonjacodes-brand-color);
  color: white;
  border: none;
  font-family: var(--sonjacodes-brand-font);
  font-size: 18px;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer;
  width: 40%;
  margin: 0 auto;
}

.WorkEnquiry input[type="submit"]:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .Home .img {
    width: 300px;
  }

  .About .img {
    width: 200px;
  }
}